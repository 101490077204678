import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { SignUpLink } from "../Sign-up";
import { FirebaseContext } from "../Firebase";
import { PasswordForgetLink } from "../Password-forget";
import * as ROUTES from "../../constants/routes";

const SignInPage = () => (
  <div>
    <h1>SignIn</h1>
    <SignInForm />
    <PasswordForgetLink />
    <SignUpLink />
  </div>
);

function SignInFormBase(props) {
  const firebase = useContext(FirebaseContext);

  let [signInForm, setSignInForm] = useState({ email: "", password: "" });
  let [error, setError] = useState(null);

  const resetForm = () => setSignInForm({ email: "", password: "" });

  const onSubmit = event => {
    if (signInForm.password && signInForm.email) {
      firebase
        .doSignInWithEmailAndPassword(signInForm.email, signInForm.password)
        .then(() => {
          resetForm();
          props.history.push(ROUTES.HOME);
        })
        .catch(error => {
          setError(error);
        });
    }
    event.preventDefault();
  };

  const onChange = event => {
    setSignInForm({ ...signInForm, [event.target.name]: event.target.value });
  };

  return (
    <form onSubmit={onSubmit}>
      <input
        name="email"
        value={signInForm.email || ""}
        onChange={onChange}
        type="text"
        placeholder="Email Address"
      />
      <input
        name="password"
        value={signInForm.password || ""}
        onChange={onChange}
        type="password"
        placeholder="Password"
      />
      <button
        type="submit"
        disabled={signInForm.password === "" || signInForm.email === ""}
      >
        Sign In
      </button>
      {error && <p className="text-danger">{error.message}</p>}
    </form>
  );
}

const SignInForm = withRouter(SignInFormBase);

export default SignInPage;
export { SignInForm };
