import React from "react";
import style from "../quizz.module.css";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { get, uniqBy, take } from "lodash";
import moment from "moment";

const { ExportCSVButton } = CSVExport;

const columns = [
  {
    dataField: "uid",
    text: "uid",
  },
  {
    dataField: "created",
    text: "Created Data",
  },
  {
    dataField: "answer1code",
    text: "Answer 1 Code",
  },
  {
    dataField: "answer1IsRight",
    text: "Answer 1 is Right",
  },
  {
    dataField: "answer1Label",
    text: "Answer 1 Label",
  },
  {
    dataField: "answer2code",
    text: "Answer 2 Code",
  },
  {
    dataField: "answer2IsRight",
    text: "Answer 2 is Right",
  },
  {
    dataField: "answer2Label",
    text: "Answer 2 Label",
  },
  {
    dataField: "answer3code",
    text: "Answer 3 Code",
  },
  {
    dataField: "answer3IsRight",
    text: "Answer 3 is Right",
  },
  {
    dataField: "answer3Label",
    text: "Answer 3 Label",
  },
  {
    dataField: "answer4code",
    text: "Answer 4 Code",
  },
  {
    dataField: "answer4IsRight",
    text: "Answer 4 is Right",
  },
  {
    dataField: "answer4Label",
    text: "Answer 4 Label",
  },
];

function convertToCSV(objArray) {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != "") line += ",";

      line += array[i][index];
    }

    str += line + "\r\n";
  }

  return str;
}

function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = convertToCSV(jsonObject);

  var exportedFilenmae = fileTitle + ".csv" || "export.csv";

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

const handleExport = (data) => {
  exportCSVFile(
    {
      uid: "UID",
      created: "Created",
      answer1code: "Answer 1 Code",
      answer1IsRight: "Answer 1 isRight",
      answer1Label: "Answer 1 Label",

      answer2code: "Answer 2 Code",
      answer2IsRight: "Answer 2 isRight",
      answer2Label: "Answer 2 Label",

      answer3code: "Answer 3 Code",
      answer3IsRight: "Answer 3 isRight",
      answer3Label: "Answer 3 Label",

      answer4code: "Answer 4 Code",
      answer4IsRight: "Answer 4 isRight",
      answer4Label: "Answer 4 Label",
    },
    data,
    "report.csv"
  );
};

const ReportScreen = (externalProps) => {
  console.log("starting point: ", externalProps.answers);
  const filtered = uniqBy(
    externalProps.answers.filter((item) => !!item.created),
    (item) => {
      return moment.unix(item.created.seconds).format();
    }
  );

  const flattened = filtered.map((answer) => {
    return {
      uid: answer.uid,
      created: moment.unix(answer.created.seconds).format(),

      answer1code: get(answer, "answers.1NJKiObZhQTHe5nmpqzQ.code", ""),
      answer1IsRight: get(answer, "answers.1NJKiObZhQTHe5nmpqzQ.isRight", ""),
      answer1Label: get(answer, "answers.1NJKiObZhQTHe5nmpqzQ.label", ""),

      answer2code: get(answer, "answers.5Hav6n7e3X7KhvRR4JMu.code", ""),
      answer2IsRight: get(answer, "answers.5Hav6n7e3X7KhvRR4JMu.isRight", ""),
      answer2Label: get(answer, "answers.5Hav6n7e3X7KhvRR4JMu.label", ""),

      answer3code: get(answer, "answers.FQwX9KD7nCIRW9c2a70M.code", ""),
      answer3IsRight: get(answer, "answers.FQwX9KD7nCIRW9c2a70M.isRight", ""),
      answer3Label: get(answer, "answers.FQwX9KD7nCIRW9c2a70M.label", ""),

      answer4code: get(answer, "answers.aW6E0yty7XZF6Tpt3M7W.code", ""),
      answer4IsRight: get(answer, "answers.aW6E0yty7XZF6Tpt3M7W.isRight", ""),
      answer4Label: get(answer, "answers.aW6E0yty7XZF6Tpt3M7W.label", ""),
    };
  });

  //    Keeping around in case someone wants to look
  //   console.log("flattened: ", flattened);
  return (
    <div>
      <div style={{ marginTop: "20px" }}>
        <a
          style={{ cursor: "pointer" }}
          onClick={() => handleExport(flattened)}
        >
          Handle Export
        </a>
        <hr />
        <BootstrapTable keyField="uid" data={flattened} columns={columns} />
      </div>
    </div>
  );
};
export default ReportScreen;
