import React from "react";
import "./not-supported-modal.css";
import ChromeLogo from "./assets/chrome-logo.svg";
import FirefoxLogo from "./assets/firefox-logo.svg";
import SafariLogo from "./assets/safari-logo.svg";

const NotSupportedModal = () => {
  return (
    <div className="not-supported-modal">
      <h2>Browser not supported</h2>
      <p>
        This website is not supported by your browser. For an optimal
        experience, here's what works:
      </p>
      <div className="browser-logo-container">
        <div className="item">
          <img className="browser-logo" src={FirefoxLogo} alt="Google Chrome" />
          <p>Firefox</p>
        </div>
        <div className="item">
          <img className="browser-logo" src={SafariLogo} alt="Google Chrome" />
          <p>Safari</p>
        </div>
        <div className="item">
          <img className="browser-logo" src={ChromeLogo} alt="Google Chrome" />
          <p>Chrome</p>
        </div>
      </div>
    </div>
  );
};

export default NotSupportedModal;
