import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Quizz from "../Quizz";
import Report from "../Report";
import style from "./app.module.css";
// import LandingPage from "../Landing";
import SignUpPage from "../Sign-up";
import SignInPage from "../Sign-in";
import PasswordForgetPage from "../Password-forget";
import HomePage from "../Home";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";
import { withErrorBoundary } from "../ErrorBoundary";

const App = () => (
  <Router className={style["mainapp"]}>
    <Route exact path={ROUTES.LANDING} component={Quizz} />
    <Route exact path={ROUTES.QUIZZ} component={Quizz} />
    <Route exact path={ROUTES.REPORT} component={Report} />
    <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
    <Route path={ROUTES.SIGN_IN} component={SignInPage} />
    <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
    <Route path={ROUTES.HOME} component={HomePage} />
    <Route path={ROUTES.ACCOUNT} component={AccountPage} />
    <Route path={ROUTES.ADMIN} component={AdminPage} />
  </Router>
);
export default withErrorBoundary(withAuthentication(App));
