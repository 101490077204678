import React, { useState, useEffect, useContext, useRef } from "react";
import AuthUserContext from "./context";
import { FirebaseContext } from "../Firebase";

const withAuthentication = Component => {
  function AuthenticationLock(props) {
    const firebase = useContext(FirebaseContext);
    let [authUser, setAuthUser] = useState(null);
    let listener = useRef(null);

    useEffect(() => {
      listener.current = firebase.auth.onAuthStateChanged(authUser => {
        setAuthUser(authUser);
      });

      return () => {
        listener.current();
      };
    }, [firebase.auth]);

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  }
  return AuthenticationLock;
};

export default withAuthentication;
