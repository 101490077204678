import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.database();
    this.firestore = app.firestore();
  }

  getNewTimestamp = () => app.firestore.Timestamp.fromDate(new Date());

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** User API ***
  user = (uid) => this.firestore.collection("users").doc(uid);
  users = () => this.firestore.collection("users");

  // *** Question API ***
  question = (uid) => this.firestore.collection("questions").doc(uid);
  questions = () => this.firestore.collection("questions");

  // *** Answer API ***
  answer = (uid) => this.firestore.collection("answers").doc(uid);
  answers = () => this.firestore.collection("answers");

  /**
   * Hard-coded proof of concept for polls using Firebase's distributed shards.
   *
   * https://firebase.google.com/docs/firestore/solutions/counters
   */
  getPollStatistics = async () => {
    try {
      const POLL_QUESTION_UID = "FQwX9KD7nCIRW9c2a70M";

      const shardsColRef = await this.firestore
        .collection(`questionStatistics/${POLL_QUESTION_UID}/shards`)
        .get();
      const shards = shardsColRef.docs;

      const results = shards.reduce(
        (currentResults, shard) => {
          const count = shard.data();

          const firstAnswerUpdatedCount = currentResults["41"] + count["41"];
          const secondAnswerUpdatedCount = currentResults["42"] + count["42"];
          const thirdAnswerUpdatedCount = currentResults["43"] + count["43"];
          const fourthAnswerUpdatedCount = currentResults["44"] + count["44"];
          const updatedTotal =
            currentResults.total +
            count["41"] +
            count["42"] +
            count["43"] +
            count["44"];

          return {
            41: firstAnswerUpdatedCount,
            42: secondAnswerUpdatedCount,
            43: thirdAnswerUpdatedCount,
            44: fourthAnswerUpdatedCount,
            total: updatedTotal,
          };
        },
        {
          41: 0,
          42: 0,
          43: 0,
          44: 0,
          total: 0,
        }
      );

      return results;
    } catch (error) {
      console.log(error);
    }
  };
}

export default Firebase;
