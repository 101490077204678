import React, { useState, useRef } from "react";
import "../quizz.css";
import QuizzOptionGrid from "./Quizz-option-grid";
import { QUESTION_STATUS } from "../quiz-status.constants";

/**
 * The second image in this quiz is a ton longer than the rest and is a pain
 * to figure out how to better hand its responsiveness with tight deadline.
 *
 * Here is a crappy hack to specially apply styles for it on web and mobile.
 */
const ANNOYING_IMAGE_INDEX = 1;

const isMediaImage = (mediaUrl) => {
  return /\.(png|jpg|gif)$/.test(mediaUrl);
};
const isMediaVideo = (mediaUrl) => {
  return /\.(mp4)$/.test(mediaUrl);
};

// Scroll to top of screen on both mobile and web.
const scrollToTop = (containerRef) => {
  window.scrollTo({ top: 0, behavior: "smooth" });
  containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
};

const CollapsableReferences = ({ className, dangerouslySetInnerHTML }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <details
      className={`references-container ${isOpen ? "" : "absolute-position"}`}
      onToggle={() => {
        setIsOpen(!isOpen);
      }}
    >
      <summary>{isOpen ? "Hide references" : "Show references"}</summary>
      <p
        className={className}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      />
    </details>
  );
};

const QuizzQuestion = (props) => {
  const ref = useRef(null);
  // can be asked, answeredWrong or answeredRight
  let [qStatus, setQStatus] = useState(QUESTION_STATUS.ASKED);
  let [answerGiven, setAnswerGiven] = useState(false);

  const isQuestion = props.question.options.some((option) => option.isRight);

  function onAnswer(selectedOption) {
    setQStatus(
      selectedOption.isRight ? QUESTION_STATUS.RIGHT : QUESTION_STATUS.WRONG
    );
    setAnswerGiven(true);
    props.onAnswer(selectedOption);
  }

  const questionMediaUrlWeb = props.question.imgUrl.replace(
    "<platform>",
    "web"
  );
  const questionMediaUrlMobile = props.question.imgUrl.replace(
    "<platform>",
    "mobile"
  );

  return (
    <div className="quizz-question-page row no-gutters">
      <div className="hidden-mobile col-2 bubble-background">
        <div className="red-circle" />
      </div>
      <div className="col-md-4 col-xs-12 d-flex flex-column justify-content-center align-items-center media-container">
        <div className={"quizz-image-container"}>
          {isMediaImage(props.question.imgUrl) ? (
            <>
              <img
                src={questionMediaUrlWeb}
                alt={`question ${props.question.order}`}
                className={"hidden-mobile"}
                style={
                  props.index === ANNOYING_IMAGE_INDEX
                    ? { width: "auto" }
                    : null
                }
              />
              <img
                src={questionMediaUrlMobile}
                alt={`question ${props.question.order}`}
                className={"hidden-web"}
                style={
                  props.index === ANNOYING_IMAGE_INDEX
                    ? { width: "auto" }
                    : null
                }
              />
            </>
          ) : null}
          {isMediaVideo(props.question.imgUrl) ? (
            <>
              <video
                src={questionMediaUrlWeb}
                autoPlay
                muted
                controls
                className="img-fluid hidden-mobile"
              />
              <video
                src={questionMediaUrlMobile}
                muted
                controls
                playsInline
                className="img-fluid hidden-web"
              />
            </>
          ) : null}
        </div>
      </div>

      <div
        ref={ref}
        className={"quizz-question-container col-md-4 col-xs-12"}
        id="quizz-container-top"
      >
        <CaseDetailTag
          status={qStatus}
          index={props.index}
          isQuestion={isQuestion}
        />
        <div
          className={
            "quizz-text-container d-flex flex-column justify-content-start"
          }
        >
          <div className="quizz-description-container">
            <div
              className={"quizz-description"}
              dangerouslySetInnerHTML={{
                __html:
                  qStatus === QUESTION_STATUS.ASKED
                    ? props.question.description
                    : props.question.answer,
              }}
            />
            {qStatus !== QUESTION_STATUS.ASKED && (
              <CollapsableReferences
                className="quizz-reference"
                dangerouslySetInnerHTML={{
                  __html: props.question.reference,
                }}
              />
            )}
          </div>
          <span
            className="quizz-question"
            dangerouslySetInnerHTML={{
              __html: props.question.question,
            }}
          />
          <QuizzOptionGrid
            scrollToTop={() => {
              // Super sketchy way to scroll to top of answers on web and mobile.
              // Ideally use `scrollToTop` on web only, and `scrollIntoView` on mobile only.
              scrollToTop(ref);
              ref.current.scrollIntoView({ behavior: "smooth" });
            }}
            qStatus={qStatus}
            options={props.question.options}
            onAnswer={onAnswer}
            pollResults={isQuestion ? null : props.pollResults}
          />
          <div className="nav-container">
            <NavBar
              onNext={() => {
                setQStatus(QUESTION_STATUS.ASKED);
                setAnswerGiven(false);
                props.onNext();
                scrollToTop(ref);
              }}
              answerGiven={answerGiven}
            />
          </div>
        </div>
      </div>
      <div className="hidden-mobile col-2 bubble-background">
        <div className="blue-circle" />
      </div>
    </div>
  );
};
export default QuizzQuestion;

const NavBar = (props) => {
  return (
    <div className="quizz-navbar d-flex justify-content-end">
      {props.answerGiven ? (
        <button
          onClick={props.onNext}
          type="button"
          className={"btn quizz-nav-button"}
        >
          Next&nbsp;&nbsp;❯
        </button>
      ) : null}
    </div>
  );
};

// Use this to show a user's progress through the quiz by showing what question they are on.
const PROGRESS_MODIFIER = 1;
const CaseDetailTag = (props) => {
  const isRight = props.status === QUESTION_STATUS.RIGHT;
  const isWrong = props.status === QUESTION_STATUS.WRONG;
  const isAsked = props.status === QUESTION_STATUS.ASKED;

  let containerStyle = "";
  let text = "ANSWER DETAILS BELOW";
  if (isAsked) {
    text = `CASE DETAILS PART ${props.index + PROGRESS_MODIFIER}`;
  } else if (!props.isQuestion) {
    containerStyle = "poll";
  } else if (isRight) {
    containerStyle = "correct";
    text = "CORRECT! ANSWER DETAILS BELOW";
  } else if (isWrong) {
    containerStyle = "incorrect";
    text = "INCORRECT. ANSWER DETAILS BELOW";
  }

  return (
    <p>
      <span className={`quizz-case-detail ${containerStyle}`}>
        <span>{text}</span>
      </span>
    </p>
  );
};
