import React, { useRef } from "react";
import style from "./quizz-option.css";
import { QUESTION_STATUS } from "../../../quiz-status.constants";

const QuizzOption = (props) => {
  const ref = useRef(null);
  const isQuestionAsked = props.qStatus === QUESTION_STATUS.ASKED;
  const isSelectedOptionRight = !isQuestionAsked && props.option.isRight;
  const isSelectedOptionWrong =
    props.qStatus === QUESTION_STATUS.WRONG && props.isSelectedOption;

  function optionClassName() {
    let className = "btn quizz-option";

    if (props.pollPercentage && props.isSelectedOption) {
      className += " quizz-option-poll";
    } else if (isSelectedOptionWrong) {
      className += " quiz-option-correction quizz-option-wrong";
    } else if (isSelectedOptionRight) {
      className += " quiz-option-correction quizz-option-right";
    }

    return className;
  }

  return (
    <button
      ref={ref}
      disabled={!isQuestionAsked}
      onClick={(e) => {
        props.takeAnswer(e);
      }}
      name={props.option.code}
      className={optionClassName(props.option)}
    >
      <>
        {props.option.label}
        <span>
          {props.pollPercentage && !isQuestionAsked
            ? `${props.pollPercentage}%`
            : null}
        </span>
      </>
    </button>
  );
};
export default QuizzOption;
