import React, { useContext, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";

import AuthUserContext from "./context";
import { FirebaseContext } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const withAuthorization = condition => Component => {
  function AuthorizationLock(props) {
    const authUser = useContext(AuthUserContext);
    const firebase = useContext(FirebaseContext);
    let listener = useRef(null);

    useEffect(() => {
      listener.current = firebase.auth.onAuthStateChanged(authUser => {
        if (!condition(authUser)) {
          props.history.push(ROUTES.SIGN_IN);
        }
      });
      return () => {
        listener.current();
      };
    }, [firebase.auth]);

    if (condition(authUser)) {
      return <Component {...props} />;
    } else {
      return null;
    }
  }
  return withRouter(AuthorizationLock);
};

export default withAuthorization;
