import React from "react";
import "./master-screen.css";
import isBrowserSupported from "../is-browser-supported";
import NotSupportedModal from "./Not-Supported-Modal";

const MasterScreen = (props) => {
  const isSupported = isBrowserSupported();

  return (
    <div
      className={
        "d-flex flex-column justify-content-center align-items-center text-white master-screen "
      }
    >
      <div className={"moving-cell moving-cell-top"} />
      <div className={"moving-cell moving-cell-bottom"} />

      {isSupported ? (
        <>
          <div
            style={{ marginTop: "5%", zIndex: 2 }}
            className={"text-center master-screen-sentence"}
          >
            <p>{props.header}</p>
            {!!props.quizResult && <p className="score">{props.quizResult}</p>}
          </div>

          <div style={{ marginTop: "60px", zIndex: 2 }}>
            <button
              onClick={props.onButtonTap}
              className={"btn btn-outline-light btn-lg master-screen-button"}
            >
              {props.buttonLabel}
            </button>
          </div>
        </>
      ) : (
        <NotSupportedModal />
      )}
    </div>
  );
};
export default MasterScreen;
