import { detect } from "detect-browser";

const isBrowserSupported = () => {
  const browser = detect();
  let isSupported = null;

  // handle the case where we don't detect the browser
  switch (browser && browser.name) {
    case "firefox":
    case "fxios": // Firefox iOS
    case "safari":
    case "ios": // Safari iOS
    case "chrome":
    case "crios": // Chrome iOS
      isSupported = true;
      break;
    default:
      isSupported = false;
      break;
  }

  return isSupported;
};

export default isBrowserSupported;
