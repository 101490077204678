import React, { useState, useEffect, useContext } from "react";
import MasterScreen from "./Master-screen";
import "./quizz.css";
import QuizzQuestion from "./Quizz-question";
import { FirebaseContext } from "../Firebase";

let nbRightAnswers = 0;
let userAnswer = {
  uid: null,
  answers: {},
};

const QuizzPageContainer = (props) => {
  return <div className={"quizz-page"}> {props.children} </div>;
};

const Quizz = () => {
  const firebase = useContext(FirebaseContext);

  let [step, setStep] = useState(-1);
  let [questions, setQuestions] = useState([]);
  let [loading, setLoading] = useState(false);
  const [pollResults, setPollResults] = useState(null);

  useEffect(() => {
    setLoading(true);
    firebase
      .questions()
      .get()
      .then(function (querySnapshot) {
        let tmp = [];
        querySnapshot.forEach(function (doc) {
          tmp.push({ ...doc.data(), uid: doc.id });
        });
        if (tmp.length > 0) {
          setQuestions(tmp.sort((q1, q2) => q1.order - q2.order));
        }
        setLoading(false);
      });
    return () => {
      // firebase.questions().off();
    };
  }, [firebase]);

  const generateNewEmptyAnswer = () => {
    const newTimestamp = firebase.getNewTimestamp();
    let newAnswer = {
      answers: {},
      created: newTimestamp,
      updated: newTimestamp,
    };
    questions.forEach((q) => {
      newAnswer.answers[q.uid] = {};
    });
    return newAnswer;
  };

  const goToNextStep = async () => {
    if (step === -1) {
      const newAnswer = generateNewEmptyAnswer();
      let doc = await firebase.answers().add(newAnswer);
      userAnswer.uid = doc.id;
      questions.forEach((q) => {
        userAnswer.answers[q.uid] = {};
      });
      setStep(step + 1);
    } else if (step === questions.length) {
      setStep(-1);
    } else {
      setStep(step + 1);
    }
  };

  const restartQuizz = () => {
    nbRightAnswers = 0;
    userAnswer = {
      uid: null,
      answers: {},
    };
    setStep(-1);
  };

  const onAnswer = async (optionSelected) => {
    if (step > -1 && step < questions.length) {
      const currentQuestionUid = questions[step].uid;
      userAnswer.answers[currentQuestionUid] = optionSelected;
      const newTimestamp = firebase.getNewTimestamp();
      if (userAnswer.uid) {
        firebase.answer(userAnswer.uid).update({
          updated: newTimestamp,
          answers: userAnswer.answers,
        });
      } else {
        alert("This answer will not be saved. Please reload the page.");
      }

      if (optionSelected.isRight) {
        nbRightAnswers++;
      }
    } else {
      console.error("answer out of bound ", step, optionSelected);
    }
  };

  if (step === -1) {
    return (
      <QuizzPageContainer>
        <MasterScreen
          onButtonTap={goToNextStep}
          header="Managing a patient with relapsed / refractory follicular lymphoma"
          buttonLabel="Get started"
        />
      </QuizzPageContainer>
    );
  } else if (step > -1 && step < questions.length) {
    return (
      <QuizzPageContainer>
        <QuizzQuestion
          index={step}
          onNext={goToNextStep}
          onAnswer={onAnswer}
          question={questions[step]}
          pollResults={pollResults}
        />
      </QuizzPageContainer>
    );
  } else if (step === questions.length) {
    const quizResult = `You scored  ${nbRightAnswers}/${questions.length}.`;

    return (
      <QuizzPageContainer>
        <MasterScreen
          onButtonTap={restartQuizz}
          header="Thanks for taking the clinical challenge."
          quizResult={quizResult}
          buttonLabel="Restart"
        />
      </QuizzPageContainer>
    );
  } else {
    return <QuizzPageContainer> Nothing to display </QuizzPageContainer>;
  }
};
export default Quizz;
