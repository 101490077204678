import React, { useEffect, useContext, useState } from "react";
import { FirebaseContext } from "../Firebase";
import Navigation from "../Navigation";

function AdminPage() {
  const firebase = useContext(FirebaseContext);

  let [loading, setLoading] = useState(false);
  let [users, setUsers] = useState([]);

  useEffect(() => {
    setLoading(true);

    firebase
      .users()
      .get()
      .then(function(querySnapshot) {
        let tmp = [];
        querySnapshot.forEach(function(doc) {
          tmp.push({ ...doc.data(), uid: doc.id });
        });
        if (tmp.length > 0) {
          setUsers(tmp);
        }
        setLoading(false);
      });

    return () => {
      firebase.users().off();
    };
  }, [firebase]);

  return (
    <div>
      <Navigation />
      <h1>Admin</h1>
      {loading && <div>Loading ...</div>}
      <UserList users={users} />
    </div>
  );
}

const UserList = ({ users }) => (
  <ul>
    {users.map(user => (
      <li key={user.uid}>
        <span>
          <strong>ID:</strong> {user.uid}
        </span>
        &nbsp;
        <span>
          <strong>E-Mail:</strong> {user.email}
        </span>
        &nbsp;
        <span>
          <strong>Username:</strong> {user.username}
        </span>
      </li>
    ))}
  </ul>
);

export default AdminPage;
