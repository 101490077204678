import React, { useState } from "react";
import QuizzOption from "./Quizz-option";
import { QUESTION_STATUS } from "../../quiz-status.constants";
import "./quizz-option-grid.css";

const SeeDetailsPill = ({ onClick }) => {
  return (
    <div id="see-details-pill" className="quizz-see-details-pill">
      <button onClick={onClick}>See answer details</button>
    </div>
  );
};

const QuizzOptionGrid = (props) => {
  let [selectedOptionCode, setSelectedOptionCode] = useState(null);

  function takeAnswer(event) {
    if (props.qStatus === "asked") {
      let selectedOption = props.options.filter(
        (o) => o.code === event.target.name
      );
      if (selectedOption.length > 0) {
        setSelectedOptionCode(selectedOption[0].code);
        props.onAnswer(selectedOption[0]);
      } else {
        console.error("wrong selected option ", event.target.name);
      }
    } else {
      console.error("can't answer twice");
    }
  }

  return (
    <div
      className={`quizz-option-grid-container ${
        props.qStatus !== QUESTION_STATUS.ASKED ? "extra-padding" : ""
      }`}
    >
      {props.qStatus !== QUESTION_STATUS.ASKED && (
        <SeeDetailsPill onClick={props.scrollToTop} />
      )}
      <div>
        {props.options.map((option) => (
          <QuizzOption
            key={option.code}
            qStatus={props.qStatus}
            option={option}
            takeAnswer={takeAnswer}
            isSelectedOption={selectedOptionCode === option.code}
            pollPercentage={
              props.pollResults
                ? Math.floor(
                    (props.pollResults[option.code] / props.pollResults.total) *
                      100
                  )
                : null
            }
          />
        ))}
      </div>
    </div>
  );
};
export default QuizzOptionGrid;
