import React, {Component} from "react";

// only classes can be error boundaries
// as seen here: https://reactjs.org/blog/2017/07/26/error-handling-in-react-16.html#introducing-error-boundaries

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
      console.error(error, errorInfo);
      
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (<div><h1>Something went wrong.</h1> <h2> We apologize for the inconvenience. Please contact our support at <a href="mailto:support@figure1.com"> support@figure1.com </a> </h2> </div>);
      }
      return this.props.children; 
    }
  }

  export const withErrorBoundary = Component => props => {
    return (
    <ErrorBoundary>
      <Component {...props}/>
    </ErrorBoundary>
  )};