import React, { useContext, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { FirebaseContext } from "../Firebase";

const SignUpPage = () => (
  <div>
    <h1>SignUp</h1>
    <SignUpForm />
  </div>
);

function SignUpFormBase(props) {
  const firebase = useContext(FirebaseContext);

  let [signUpForm, setSignUpForm] = useState({
    username: "",
    email: "",
    passwordOne: "",
    passwordTwo: ""
  });
  let [error, setError] = useState(null);

  const resetForm = () =>
    setSignUpForm({
      username: "",
      email: "",
      passwordOne: "",
      passwordTwo: ""
    });

  const onSubmit = event => {
    if (
      signUpForm.username !== "" &&
      signUpForm.email !== "" &&
      signUpForm.passwordOne !== "" &&
      signUpForm.passwordOne === signUpForm.passwordTwo
    ) {
      const { username, email, passwordOne } = signUpForm;
      firebase
        .doCreateUserWithEmailAndPassword(email, passwordOne)
        .then(authUser => {
          // Create a user in your Firebase realtime database
          return firebase.user(authUser.user.uid).set({
            username,
            email
          });
        })
        .then(() => {
          resetForm();
          props.history.push(ROUTES.HOME);
        })
        .catch(error => {
          setError(error);
        });
    }
    event.preventDefault();
  };

  const onChange = event => {
    setSignUpForm({ ...signUpForm, [event.target.name]: event.target.value });
  };

  return (
    <form onSubmit={onSubmit}>
      <input
        name="username"
        value={signUpForm.username || ""}
        onChange={onChange}
        type="text"
        placeholder="Full Name"
      />
      <br />
      <input
        name="email"
        value={signUpForm.email || ""}
        onChange={onChange}
        type="text"
        placeholder="Email Address"
      />
      <br />
      <input
        name="passwordOne"
        value={signUpForm.passwordOne || ""}
        onChange={onChange}
        type="password"
        placeholder="Password"
      />
      <br />
      <input
        name="passwordTwo"
        value={signUpForm.passwordTwo || ""}
        onChange={onChange}
        type="password"
        placeholder="Confirm Password"
      />
      <br />
      <button
        className="btn"
        disabled={
          signUpForm.username === "" ||
          signUpForm.email === "" ||
          signUpForm.passwordOne === "" ||
          signUpForm.passwordOne !== signUpForm.passwordTwo
        }
        type="submit"
      >
        Sign Up
      </button>
      {error && <p>{error.message}</p>}
    </form>
  );
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = withRouter(SignUpFormBase);

export default SignUpPage;
export { SignUpForm, SignUpLink };
