import React, { useState, useEffect, useContext } from "react";
import ReportScreen from "./ReportScreen";
import style from "./quizz.module.css";
import { FirebaseContext } from "../Firebase";

const Report = () => {
  const firebase = useContext(FirebaseContext);

  let [answers, setAnswers] = useState([]);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    firebase
      .answers()
      .get()
      .then(function(querySnapshot) {
        let tmp = [];
        querySnapshot.forEach(function(doc) {
          tmp.push({ ...doc.data(), uid: doc.id });
        });
        if (tmp.length > 0) {
          setAnswers(tmp);
        }
        setLoading(false);
      });
    return () => {
      // firebase.questions().off();
    };
  }, [firebase]);



  const PageContainer = props => {
    return <div className={style["quizz-page"]}> {props.children} </div>;
  };

     return  <PageContainer>
        <ReportScreen answers={answers}/>
      </PageContainer>

};
export default Report;
